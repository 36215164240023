import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import hpInfoStore from './hpInfoStore';
import boardNoStore from './boardNoStore';
import registInfoStore from './registInfoStore';
import loginTypeStore from './loginTypeStore';
import redirectUrlStore from './redirectUrlStore';
import reserveStore from './reserveStore';

export default createStore({
    plugins: [
        createPersistedState({
            paths: ['hpInfoStore', 'boardNoStore', 'registInfoStore', 'loginTypeStore','redirectUrlStore','reserveStore']
        })
    ],
    state: {
        kumcApi: {
            searchApi: { // 통합검색
                search: '/api/search/search.do', // 통합검색
                popword: '/api/search/popword.do',    //인기검색어
                recommend: '/api/search/recommend.do', // 연관검색
            },
            articleApi: { // 게시판 컨트롤러
                article: '/api/article/', // 게시글 조회, 등록, 삭제 // 상세조회
                boardInfo: '/api/board/',    //게시판정보
                mainContents:'/api/support/mainContent.do'
            },                            
            registApi: { // 회원가입 컨트롤러
                encData: '/api/user/api/getEncData.do', // 본인인증 encData
                encDataChk: '/api/user/api/phone/result.do', // 본인인증 확인 결과
                ipinEncData: '/api/user/api/getIpinEncData.do', // 아이핀 encData
                ipinEncDataChk: '/api/user/api/ipin/result.do', // 아이핀 확인 결과
                parentChk: '/api/user/api/member/parentCheck.do', // 보호자 확인
                checkId: '/api/user/checkDuplicate.do', // ID 중복체크
                findId: '/api/user/findId.do', // ID 찾기
                findPassword: '/api/user/findPassword.do', // 비밀번호 찾기
                registerReg: '/api/user/registerReg.do', // 회원가입
                checkDuplicateDi: '/api/user/checkDuplicateDi.do', // 아이핀 확인 결과
                pwChange: '/api/user/pwChange.do', // 비밀번호 변경
                updateMember: '/api/user/updateMember.do', // 회원정보 수정
            },
            loginApi: { // 로그인 컨트롤러
                login: '/member/login.do', // 로그인
                logout: '/member/logout.do', // 로그아웃,
                sleep: '/member/sleep.do', // 휴먼회원 처리
                sleepModify: '/member/sleepModify.do', // 휴먼회원 정보수정
                ssoAuth : 'https://auth.korea.ac.kr/directLoginNew.jsp'
            },
            myPageApi: {
                myInfo: '/api/info.do',                                         // 회원정보 - 세션정보
                checkPrivacy: '/api/mypage/info/check.do',                      // 비밀번호 확인 프로세스
                pwChange: '/api/mypage/info/pwChange.do',                       // 비밀번호 변경
                updateInfo: '/api/mypage/info/modify.do',                       // 회원 기본 정보 수정                
                withdraw: '/api/mypage/info/withdraw.do',                       // 회원 탈퇴
                mainInfo : '/api/mypage/main.do',	                            //메인페이지 컨텐츠 정보
            },       
            bannerApi: {
                banner: '/banner/api/banner.do'
            },
            equipmentApi: { //장비/공간정보
                list : '/api/support/equipment/list.do',
                info : '/api/support/equipment/view.do',
                typeList : '/api/support/equipment/type/list.do'
            },
            reservationApi : {
                ipRegister : '/api/support/reserve/ip/register.do',             //IP신청 등록
                equipRegister : '/api/support/reserve/equip/register.do',       //장비/공간/통계 예약
                notAvailableList : '/api/support/reserve/notAvailableList.do',  //예약불가목록
                reservationList : '/api/support/reserve/list.do',               //예약목록
                reservationInfo : '/api/support/reserve/equip/info.do',         //예약 상세 정보
                ipList : '/api/support/mypage/ip/list.do',
                equipList : '/api/support/mypage/equip/list.do',
                cancle : '/api/support/mypage/reserveCancle.do'                
            }
        }
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        /**
         * 병원 domain / 병원 넘버 코드 / 병원 코드 / 다국어 타입
         */        
        hpInfoStore: hpInfoStore,      
        // boardNo
        boardNoStore: boardNoStore,
        // 회원가입 정보
        registInfoStore: registInfoStore,
        // 로그인 시 부여될 type
        loginTypeStore: loginTypeStore,
        // redirectURL
        redirectUrlStore: redirectUrlStore,
        // 예약
        reserveStore : reserveStore
    }
});
