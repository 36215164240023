import store from "@/store";
import router from ".";
import axios from 'axios';

// 로그인 사용자 밀어내기
function isLoginCheck(next, text) {
    if (store.state.loginTypeStore.status == true) {
        //alert(`로그인이 되어있습니다.\n${text}을(를) 하려면 로그아웃을 해주시길 바랍니다.`);
        router.replace('/kr/index.do');
    } else {
        return next();
    }
}

// 비로그인 사용자 밀어내기
function isNotLoginCheck(to, from, next) {
    axios.get(store.state.kumcApi.myPageApi.myInfo).then(response => {
        if (response.data == '') {
            alert('로그인 후 이용이 가능합니다.');
            store.commit('getLoginStatus', false);
            store.commit("getRedirectUrl", to.href);
            router.push('/kr/login.do');
        } else {
            return next();
        }
    });
}


export {isLoginCheck, isNotLoginCheck};