import { createRouter, createWebHistory } from 'vue-router';
import krRouter from './krRouter';

const routes = [
    {
        path: '',
        component: () => import('../views/kr/main/MainView.vue'),
        beforeEnter: (to, from, next) => {
            if (window.location.pathname == '/') {
                window.location.pathname = '/kr/index.do';
            } else {
                return next();
            }
        }
    },    
    ...krRouter,
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: () => import('../views/kr/error/Error.vue'),
        alias: [
            '/web/msrc/home',
        ]
    } 
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
