<template>
    <Spinners v-if="isLoading"></Spinners>
    <div id="skipNav">
        <a href="#contioner">본문 바로가기</a>
        <a href="#header">글로벌 네비게이션 바로가기</a>
    </div>
    <router-view />
</template>

<script>
import Spinners from './components/Spinners.vue';
export default {
     data() {
        return {
            isLoading: false,
            urlList: [],
        }
    },
    components: {
        Spinners
    },
    watch: {
        $route(to, from) {
            if (to != from) {
                // 이동 시 스크롤 x top
                window.scrollTo(0, 0);
                // 로그인 체크
                this.loginChk();
                //this.createCss([
                //    "/resource/css/com/common.css"
                //]);
            }
        },
    },
    created() {
        var routeNm = location.pathname;

        this.axios.interceptors.request.use(
            config => {
                if(config.url !== '/api/info.do'){
                    this.setLoading(true);
                    this.urlList.push([config.url, config.params]);
                }
                return config;
            },
            error => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        );

        this.axios.interceptors.response.use(
            response => {
                if(response.config.url !== '/api/info.do'){
                    if(JSON.stringify(this.urlList[this.urlList.length - 1]) == JSON.stringify([response.config.url, response.config.params])) {
                        this.setLoading(false);
                    }                
                }
                return response;
            },
            error => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        );
    },
    methods: {
        setLoading(bool){
            this.isLoading = bool;
        },
        createCss(item) {
            
        },
        loginChk() {
            // 로그인을 하면 true
            if (this.$store.state.loginTypeStore.status) {
                console.log("login 감지");
                // 일정 시간 이후 status 변경(session time > status time)
                this.$store.dispatch("setLoginStatus", true);
                // info.do 호출로 session 확인
                this.axios
                    .get(this.$store.state.kumcApi.myPageApi.myInfo)
                    .then((res) => {
                        // info.do 가 호출가능하므로 갱신
                        if (res.data != "") {
                            this.$store.commit("getLoginStatus", true);
                            this.$store.dispatch("setLoginStatus", true);
                            // info.do 가 호출불가능하므로 삭제
                        } else {
                            this.$store.commit("getLoginStatus", false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.axios
                    .get(this.$store.state.kumcApi.myPageApi.myInfo)
                    .then((res) => {
                        if (res.data != "") {
                            this.$store.commit("getLoginStatus", true);
                            this.$store.dispatch("setLoginStatus", true);
                        } else {
                            this.$store.commit("getLoginStatus", false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }
};
</script>

<style>
    @import "@/assets/resource/css/com/jquery-ui.css";
    @import "@/assets/resource/css/com/slick.css";
    @import "@/assets/resource/css/com/swiper-bundle.min.css";
    @import "@/assets/resource/css/com/reset.css";
    @import '@/assets/resource/css/fonts/font.css';
    @import '@/assets/resource/css/com/style.css';
    @import '@/assets/resource/css/com/common.css';
    @import '@/assets/resource/css/pages/subpage.css';
    @import '@/assets/resource/css/pages/subpage_mo.css';
</style>
