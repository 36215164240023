import VueCookie from "vue-cookies";
const methods = {
    // kr, str 삭제 api 추가 - /kr/B021/list.do => /api/B021.do
    // board
    changeStr(item, str) {
        var result = '';
        var strResult = '';
        if (item.indexOf('/kr') == 0) {
            result = item.replace('/kr', '');
            result = result.replace(str, '');
            strResult = '/api/board' + result;
        } else {
            result = item.replace(str, '');
            strResult = '/api/board' + result;
        }
        return strResult;
    },

    // download func
    fileDownload(attach, name) {
        this.axios({
            url: `/downloadFile.do?attachNo=${attach}`,
            method: 'GET',
            responseType: 'blob'
        })
        .then(res => {
            var fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileUrl;
            fileLink.setAttribute('download', `${name}`);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
        });
    },
    changeInput(input, e) {
        this[input] = e.target.value;
    },
    goArticle(article) {
        if (article.linkUrl && article.linkUrl != '') {
            if (article.linkMethod == '_blank') {
                window.open(article.linkUrl);
            } else {
                location.href = article.linkUrl;
            }
        } else {
            this.$router.push(`view.do?articleNo=${article.articleNo}`);
        }
    },
    chatbot() {
        window.open('http://고대안암병원.kr:11005/KAH', 'KUMC_CHATBOT', 'width=480, height=640');
    },
    goMenu(menu) {
        if (menu.menuType == 'L' && menu.menuUrl.indexOf('http') >= 0) {
            if (menu.linkMethod == '_blank') {
                window.open(menu.menuUrl);
            } else {
                location.href = menu.menuUrl;
            }
        } else {
            if (menu.linkMethod == '_blank') {
                window.open(menu.menuUrl);
            } else {
                this.$router.push(menu.menuUrl);
            }            
        }
    },
    /**
     * 날자 형식으로 리턴
     * ref) $makeDateFormat(20140101);  // 2014.01.01
     * @param YYYYMMDD
     * @returns YYYY.MM.DD
     */
    makeDateFormat: function (strDate) {

        var delimiter = ".";    //날짜 형식 구분자

        if (isNaN(strDate) || strDate == null) {
            strDate = "";
        }

        var size = strDate.length;
        if (size >= 6) {
            strDate = strDate.substring(0, 4) + delimiter + strDate.substring(4, 6) + delimiter + strDate.substring(6);
        } else if (size >= 4) {
            strDate = strDate.substring(0, 4) + delimiter + strDate.substring(4);
        }

        return strDate;
    },
    dayFormat(date, idx) {
        var rtn = date.split(' ~ ')[idx];

        if (rtn.length > 10) {
            rtn = rtn.substring(0, 10);
        }

        return rtn;
    },
    getToday() {
        var delimiter = "-";
        var today = new Date();
        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);
        return year + delimiter + month + delimiter + day;
    },
    timestempToDateFormat(timestemp) {
        if(timestemp != undefined && timestemp){
            var delimiter = "-";
            var date = new Date(timestemp);
            return date.getFullYear() + delimiter + ('0' + (date.getMonth() + 1)).slice(-2) + delimiter + ('0' + date.getDate()).slice(-2);
        }else{
            return '';
        }
    },
    /**
     * 특정 날짜에 대해 지정한 값만큼 가감(+-)한 날짜를 반환
     * ref : this.$addDate(aType, aDay, aDate)
     *       20130304 로부터 2달뒤 ==> this.$addDate("m", 2, "20130304");
     * @param aType 가감타입 : y(연도), m(월),  d(일), md(월가감 날짜까지 표현)
     * @param aDay 가감일
     * @param aDate 가감기준일
     * @returns {String}
     */
    addDate(aType, aDay, aDate) {
        var delimiter = "-";
        var yyyy;
        var mm;
        var dd;
        var cDate;
        var cYear, cMonth, cDay;

        if (aDate == undefined) {
            aDate = this.getToday();
        }

        aDate = aDate.replace(/[\-]/gi, "");

        yyyy = aDate.substr(0, 4);
        mm = aDate.substr(4, 2);
        dd = aDate.substr(6, 2);

        if (aType == "y") {
            yyyy = (yyyy * 1) + (aDay * 1);
        } else if (aType == "m" || aType == "md") {
            mm = (mm * 1) + (aDay * 1);
        } else if (aType == "d") {
            dd = (dd * 1) + (aDay * 1);
            if (aDay < 0) dd++;
            else dd--;
        }

        cDate = new Date(yyyy, mm - 1, dd); // 12월, 31일을 초과하는 입력값에 대해 자동으로 계산된 날짜가 만들어짐.
        cYear = cDate.getFullYear();
        cMonth = cDate.getMonth() + 1;
        cDay = cDate.getDate();

        cMonth = cMonth < 10 ? "0" + cMonth : cMonth;
        cDay = cDay < 10 ? "0" + cDay : cDay;

        return cYear + delimiter + cMonth + delimiter + cDay;
    },
    removeContentTag(text) {
        text = text.replace(/<br\/>/ig, "\n");
        text = text.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "");
        text = text.replace(/(<([^>]+)>)/gi, "");
        text = text.replace(/&nbsp;/gi, "");
        return text;
    },
    dateToStr(d,dot){
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        return `${year}`+dot+`${month >= 10 ? month : '0' + month}`+dot+`${date >= 10 ? date : '0' + date}`;
    },
    comma(val){
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberOnly(evt){
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    },    
    async getMenuList(){
        var menuList;
        await this.axios.get('/menu.do', {
            params: {
                instNo: this.$store.state.hpInfoStore.instNo,
                lang: this.$store.state.hpInfoStore.lang
            }
        })
        .then((res) => {
            setTimeout(() => {
                console.log("after");
                
                //app.config.globalProperties.menuList = res.data;
                menuList = res.data;
            }  
            
            );
        })
        .catch(err => console.log(err));
        return menuList;
    }
};

export default {
    install(Vue) {
        Vue.config.globalProperties.$changeStr = methods.changeStr;
        Vue.config.globalProperties.$fileDownload = methods.fileDownload;
        Vue.config.globalProperties.$changeInput = methods.changeInput;
        Vue.config.globalProperties.$goArticle = methods.goArticle;
        Vue.config.globalProperties.$chatbot = methods.chatbot;
        Vue.config.globalProperties.$goMenu = methods.goMenu;
        Vue.config.globalProperties.$dayFormat = methods.dayFormat;
        Vue.config.globalProperties.$makeDateFormat = methods.makeDateFormat;
        Vue.config.globalProperties.$addDate = methods.addDate;
        Vue.config.globalProperties.$getToday = methods.getToday;
        Vue.config.globalProperties.$timestempToDateFormat = methods.timestempToDateFormat;
        Vue.config.globalProperties.$removeContentTag = methods.removeContentTag;
        Vue.config.globalProperties.$dateToStr = methods.dateToStr;
        Vue.config.globalProperties.$comma = methods.comma;
        Vue.config.globalProperties.$numberOnly = methods.numberOnly;
        Vue.config.globalProperties.$getMenuList = methods.getMenuList;
    }
};